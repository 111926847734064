import HTMLReactParser from "html-react-parser";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getConfig from "next/config";
import ActionConstants from "../../src/action-constants/app-actions";
import { requestRRConfig } from "../../src/actions/common-page-actions";
import { requestAutoGatePopupConfigData } from "../../src/actions/location-lock-actions";
import { checkCartAndLoginCookie, FnpPageType, getFnpPageType, lazyLoadImgs } from "../../src/utils/common";
import { convertComponentCurrency } from "../../src/utils/currency-utils";
import OneTapLogin from "../../src/utils/googleOneTapLogin";
import widgetImpressionFun from "../../src/utils/impressions/widgetImpression";
import { reviewSection } from "../../src/utils/plp-helper";
import PLPMicroSiteWithAggregateRating from "../desktop/plp/microSite-aggregate-ratings";
import { FEATURE_NAME } from "./constants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../src/analytics/clevertapConstants";

const DesktopReviewSlider = dynamic(() => import("../desktop/review-slider"));

const CategoryReviews = dynamic(() => import("../mobile/plp/plp-category-reviews"));

const RecentlyViewed = dynamic(() => import("../mobile/pdp/recently-viewed"), {
  ssr: false,
});
const SVNBreadCrumbs = dynamic(() => import("./svn-breadcrumbs"));

const SomethingWentWrong = dynamic(() => import("./error"), {
  ssr: false,
});
const CMSWidget = dynamic(() => import("./CMSWidget"), {
  ssr: false,
});

/**
 * The MicroSite component takes the svn content and passes it to
 * HTMLReactParser to produce a valid React Component
 *
 * @param {string} microSiteData svn string content.
 * @returns {React.ReactElement} React Component of the svn data.
 */
function DesktopMicroSite({ ...params }) {
  const dispatch = useDispatch();
  const {
    microSiteData,
    widgets,
    categoryRating,
    categoryReviews,
    bestRating,
    productCategoryName,
    categoryReviewsCollection,
    total,
  } = params;
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();
  const appConfigs = useSelector((state) => state.appConfigs);

  const { currencies: currenciesList } = useSelector((state) => state.currenciesList);
  const selectedCurrency = useSelector((state) => state.appData.selectedCurrency);
  const { microSite: data, pageType, breadcrumbs } = microSiteData;
  const CMSWidgets = widgets && JSON.parse(widgets);
  const CMSWidgetsIdsMap = new Map();
  if (CMSWidgets?.length) {
    CMSWidgets?.forEach((widget, index) => {
      CMSWidgetsIdsMap.set(widget.divId, index);
    });
  }
  const userDetails = useSelector((state) => state.userDetails);
  const isUserLoggedIn = userDetails?.checkLoginInfo?.loggedin;
  const userType = userDetails?.checkLoginInfo?.userType;
  const host = publicRuntimeConfig.D_HOST;
  const [previousPathName, setPreviousPathName] = useState("");
  const basePageViewedData = {
    complete_url: `${host}${router.asPath}`,
    clean_url: `${host}${router.asPath}`.split("?")[0],
    internal_source_type: previousPathName,
    internal_source: previousPathName,
    page_name: pageType === "home" ? cleverTapCommonWords.HOME : `${router.asPath}`,
    page_type: pageType === "home" ? cleverTapCommonWords.HOME : cleverTapCommonWords.MICROSITE,
    login_status: isUserLoggedIn,
    is_international: appConfigs?.catalogId !== "india",
  };
  const clevertapPageViewed =
    pageType === "home" ? cleverTapCommonEvents.homePageViewed : cleverTapCommonEvents.micrositePageViewed;

  const conditionalPageViewedData =
    pageType === "home"
      ? {
          page_name: cleverTapCommonWords.HOME,
          plp_category: null,
          plp_rating: null,
          plp_review_count: null,
          total_products: null,
          is_pill_available: null,
          is_banner_available: null,
          is_filter_available: null,
        }
      : {
          plp_category: productCategoryName,
          plp_rating: categoryRating,
          plp_review_count: categoryReviews,
          total_products: total,
          is_pill_available: false,
          is_banner_available: false,
          is_filter_available: false,
          is_microsite: true,
        };

  const pageViewedData = {
    ...basePageViewedData,
    ...conditionalPageViewedData,
  };

  useEffect(() => {
    const { referrer } = document;
    if (referrer) {
      setPreviousPathName(new URL(referrer).pathname);
    }

    if (selectedCurrency && (!checkCartAndLoginCookie() || userType)) {
      callClevertapEvent(clevertapPageViewed, pageViewedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency, userType]);

  useEffect(() => {
    if (productCategoryName !== undefined) {
      widgetImpressionFun();
    }
  }, [productCategoryName]);

  useEffect(() => {
    lazyLoadImgs();
    dispatch(requestRRConfig({ featureName: FEATURE_NAME.DATE_LOCK }));

    dispatch(requestAutoGatePopupConfigData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     *
     * Method to get currency and start conversion
     *
     * @param {*} currency currency
     */
    const convertCurrency = async (currency) => {
      if (currenciesList?.length) {
        const selectedCurrencyDetails = currenciesList.filter((x) => x.currency.id === currency);
        if (selectedCurrencyDetails?.length > 0) {
          const currencyFactor = selectedCurrencyDetails[0].currency.value;
          convertComponentCurrency(currencyFactor, selectedCurrency, "span");
        }
      }
    };
    convertCurrency(selectedCurrency);
  }, [currenciesList, selectedCurrency]);

  /**
   *This method provides a scroll behavior on click of the global Review Component which in scrolls down to the What customers are saying component
   *
   */
  const scrollToReviews = () => {
    reviewSection("reviews-section", 140);
  };

  /**
   * generates html markup for the menu
   *
   * @returns {React.ReactElement} - retuns the html markup elements.
   */
  function createMarkup() {
    const parseOptionsNode = {
      replace: (node) => {
        if (node.attribs && node.attribs.id === "recentlyvieweedproducts") {
          return <RecentlyViewed />;
        }
        if (node.attribs && node.attribs.id === "categoriesRating") {
          if (categoryRating && categoryReviews)
            return (
              <CategoryReviews catRating={categoryRating} catReviews={categoryReviews} handler={scrollToReviews} />
            );
          return <></>;
        }
        if (node.attribs && node.attribs.id === "customersReviews") {
          if (categoryReviewsCollection && categoryReviewsCollection.length)
            return <DesktopReviewSlider reviews={categoryReviewsCollection} />;

          return <></>;
        }

        if (!!CMSWidgetsIdsMap.size && node.attribs && CMSWidgetsIdsMap.has(node.attribs.id)) {
          const item = CMSWidgets[CMSWidgetsIdsMap.get(node.attribs.id)];
          return (
            <CMSWidget
              title={item.title}
              productIds={item.productIds}
              viewAll={item.viewAll}
              trackingParam={item.trackingParam}
            />
          );
        }

        return node;
      },
    };
    return HTMLReactParser(data, parseOptionsNode);
  }

  /**
   * This method returns an react element for SVN pages without aggregate values.
   *
   * @returns {React.element} parsing SVN Pages.
   */
  const renderContentContainer = () => {
    return (
      <div className="content-container for-desktop">
        {breadcrumbs?.length > 0 && <SVNBreadCrumbs breadcrumbs={breadcrumbs} />}
        {createMarkup()}
      </div>
    );
  };

  if (!data || data === ActionConstants.ECONNABORTED) return <SomethingWentWrong />;
  return (
    <>
      <Head>
        {getFnpPageType() === FnpPageType.INFO && (
          <>
            <link rel="stylesheet" href="/d/assets/css-bbb/info/info-lib.min.css" />
            <link rel="stylesheet" href="/d/assets/css-bbb/info/info-below-fold.min.css" />
          </>
        )}
      </Head>
      <OneTapLogin />
      {!!categoryRating && !!categoryReviews && pageType === FnpPageType.CATEGORY ? (
        <>
          <PLPMicroSiteWithAggregateRating
            categoryRating={categoryRating}
            categoryReviews={categoryReviews}
            bestRating={bestRating}
            productCategoryName={productCategoryName}
            renderContentContainer={renderContentContainer}
          />
        </>
      ) : (
        renderContentContainer()
      )}
    </>
  );
}
export default DesktopMicroSite;
